<template>
  <div v-if="visible" class="related-sites popup-zone" :class="popupClass">
    <div class="popup-inner" :class="popupInnerClass">
      <div class="button-group swiper-button-group">
        <div class="swiper-button-prev site-swiper-prev" slot="button-prev">
          <span class="material-icons-round">chevron_left</span>
        </div>
        <div class="swiper-button-next site-swiper-next" slot="button-next">
          <span class="material-icons-round">chevron_right</span>
        </div>
      </div>
      <!-- inner-content -->
      <div class="inner-content">
        <!-- 목록 -->
        <div class="detail-content">
          <div class="tit-area">
            <h2>POPUP ZONE</h2>
            <div v-if="isViewAll || isMobileMode" class="count-group mob-visible-flex">
              <strong class="color-primary-lighten">{{ activeIndex }}</strong>/<span>{{ popupList.length }}</span>
            </div>
          </div>
          <div class="list-area">
            <div class="list-wrapper">
              <swiper
                ref="popupSwiper"
                class="swiper list-swiper related-site-list"
                :options="relatedSitesListSwiperOption"
                @slideChange="onSlideChange"
              >
                <!-- list-item -->
                <swiper-slide v-for="item in popupList" :key="item.popupOid">
                  <div v-if="isContentsType( item )" class="list-item contents-box">
                    <!-- scroll area -->
                    <div class="scroll-element" v-bar="{ preventParentScroll: true }">
                      <!-- el1 -->
                      <div>
                        <!-- 에디터에서 입력한 내용은 아래 contents에. -->
                        <div class="contents">
                          <div class="tc">
                            <p v-html="item.contents"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="list-item">
                    <a @click="setImgUrl(item)">
                      <img alt="" :src="getImage(item)"/>
                    </a>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>
        <div v-if="isViewAll || isMobileMode" class="content-footer view-all-footer">
          <button @click="onCloseViewAll">닫기</button>
        </div>
        <div v-else class="content-footer">
          <div class="content-footer__button-group">
            <div class="count-group web-visible-flex">
              <strong class="color-primary-lighten">{{ activeIndex }}</strong>/<span>{{ popupList.length }}</span>
            </div>
            <div class="button-group">
              <div class="button-play">
                <!-- pause -->
                <button v-show="popupSwiperPlay" class="swiper-button-pause" @click="popupSwiperPlay = false">
                  <img src="@/assets/images/portal/icon/main/icon_pause.svg" alt="일시정지">
                </button>
                <!-- play -->
                <button v-show="!popupSwiperPlay" class="swiper-button-pause" @click="popupSwiperPlay = true">
                  <img src="@/assets/images/portal/icon/main/icon_play.svg" alt="재생">
                </button>
              </div>
              <button class="button-view-all" @click="onClickViewAll">
                <img src="@/assets/images/portal/icon/main/icon_apps.svg" alt="전체보기">
              </button>
            </div>
          </div>
          <div class="content-footer__close">
            <button @click="close">
              <span class="material-icons-round">close</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";

export default {
  components : {},
  props : {
    popupList : {
      type : Array,
      required : false,
    },
    visible : {
      type : Boolean,
      required : false,
      default : false,
    },
    ignoreCookie : {
      type : Boolean,
      required : false,
      default : false,
    },
    // 전체보기 여부
    isViewAll : {
      type : Boolean,
      required : false,
      default : false,
    },
    // 부모에서 scroll 의 overflow 를 제어할 건지 여부
    isParentScrollControl : {
      type : Boolean,
      required : false,
      default : false,
    },
  },

  data() {
    return {
      // 관련 사이트 목록 스와이퍼 옵션
      relatedSitesListSwiperOption : {
        spaceBetween : 40,
        centerInsufficientSlides : true, // 센터 정렬
        slidesPerView : this.isViewAll ? 3 : 1,     // 보여지는 슬라이드 갯수
        slidesPerGroup : 1,    // 슬라이드 숫자
        loop: 1 !== this.popupList.length, // 팝업 카운트 때문에 활성화 함
        loopFillGroupWithBlank : true,
        loopedSlides: this.popupList.length,
        autoplay : {
          delay : 5000,
          disableOnInteraction : true,
        },
        breakpoints : {
          1024 : {
            slidesPerView: 2 > this.popupList.length ? this.popupList.length : 2,
            spaceBetween : 32
          },
          768 : {
            slidesPerView : 1,
            spaceBetween : 0
          },
        },
        navigation : {
          nextEl : ".site-swiper-next",
          prevEl : ".site-swiper-prev",
        },
      },

      activeIndex : 1, // 활성화 되어 있는 스와이퍼 인덱스

      popupSwiperPlay : true,
      isPopupVisible : false,
      isMobileMode : this.$constant.MOBILE_SIZE.WIDTH >= window.innerWidth,
    };
  },

  computed : {
    popupClass() {
      return [
        this.visible ? 'visible' : '',
        (this.isViewAll || this.isMobileMode) ? 'is-view-all' : '',
      ];
    },

    popupInnerClass() {
      return {
        "popup-count-1" : 1 === this.popupList.length,
        "popup-count-2" : 2 === this.popupList.length && !this.isMobileMode,
      };
    }
  },

  watch : {
    // swiper autoplay
    popupSwiperPlay() {

      if ( this.popupSwiperPlay ) {
        this.$refs.popupSwiper.swiper.autoplay.start();
      }
      else {
        this.$refs.popupSwiper.swiper.autoplay.stop();
      }
    },

    popupList() {

      if ( this.popupList && this.popupList.length > 0 ) {

        this.isPopupVisible = true;

        if ( !this.ignoreCookie ) {
          this.checkCookie();
        }

      }
      else {
        this.isPopupVisible = false;
      }
    },

    isViewAll( newVal ) {

      console.log("isViewAll", newVal )

      // 전체보기 시 스크롤 비활성화
      this.setScrollOverflow();

      // isViewAll이 true일 때 slidesPerView를 3으로 변경
      // 실제 리스트가 3개보다 적을 경우 화면이 멈추는 현상이 발생합니다.
      if ( !this.isViewAll ) {
        this.relatedSitesListSwiperOption.slidesPerView = 1;
      }
      // isViewAll 인데 팝업리스트가 3개 이하일 경우
      else if ( this.popupList.length <= 3 ) {
        this.relatedSitesListSwiperOption.slidesPerView = this.popupList.length;
      }
      // 팝업리스트가 3개 초과일 경우 3으로 변경
      else {
        this.relatedSitesListSwiperOption.slidesPerView = 3;
      }

      if ( this.$refs.popupSwiper?.swiper ) {
        // swiper 옵션을 동적으로 업데이트
        this.$nextTick( () => {

          this.$refs.popupSwiper.swiper.params.slidesPerView = this.relatedSitesListSwiperOption.slidesPerView;
          // // 0번째 index로 이동 ( 이동할 슬라이드 인덱스, 애니메이션 지속 시간, 스냅 여부( true : 스냅 포인트에 맞춰 이동 / false : 정확한 위치로 이동 ) )
          this.$refs.popupSwiper.swiper.slideTo( 0, 0, false );
          this.$refs.popupSwiper.swiper.update();
        } );
      }

    },
  },

  mounted() {
    console.log("팝업존 mounted")
    this.setScrollOverflow();
  },
  methods : {

    // 슬라이드가 변경되었을 경우
    onSlideChange() {
      // 활성화 되어있는 index 를 세팅합니다.
      this.activeIndex = this.$refs.popupSwiper.swiper.realIndex + 1;
    },

    getImage( item ) {

      if ( this.$validate.isEmpty( item ) || this.$validate.isEmpty( item.fileList ) ) {

        return;
      }

      // [YP20240403] 2024-04-03 썸네일 파일을 찾아 getIamge로 path 넘길 수 있게 수정
      const thumbnailFile = item.fileList.filter( file => this.$constant.FILE_TYPE.IMG !== file.fileType )[0];

      return this.$util.fileUtils.getThumbnailPath( thumbnailFile.storageFileUid, item.width + "_" + item.height );
    },

    setScrollOverflow() {

      // 부모에서 컨트롤하고 있을 경우 return
      if ( this.isParentScrollControl ) {
        return;
      }

      console.log("팝업존에서 스크롤 건드림;")

      if ( this.isPopupVisible ) {
        document.querySelector( '.wrapper' ).style.overflow = "hidden"
      }
      else {
        document.querySelector( '.wrapper' ).style.overflow = ""
      }
    },

    checkCookie() {

      if ( Cookies.get( this.$constant.POPUP_HIDE_TODAY_LIST ) ) {
        this.closePopup();
      }
    },

    /* 링크 타입이 새창인지, 페이지 이동인지 구분하여 이동 */
    setImgUrl( item ) {

      if ( this.$validate.isEmpty( item ) || this.$validate.isEmpty( item.linkUrl ) ) {
        return;
      }

      // [YP20240412] 2024-04-12 linkTypeFlag 값을 가지고 있으면 openWindowYn값으로 변경
      if ( this.$validate.isNotEmpty( item.linkTypeFlag ) ) {
        if ( this.$constant.POPUP_TYPE.LINK.PAGE === item.linkTypeFlag ) {
          item.openWindowYn = this.$constant.POPUP_TYPE.OPEN_WINDOW_YN.NO;
        }
        else {
          item.openWindowYn = this.$constant.POPUP_TYPE.OPEN_WINDOW_YN.YES;
        }
      }

      // 페이지 이동
      // [YP20240412] 2024-04-12 linkTypeFlag -> OpenWindowYn으로 변경
      if ( this.$constant.POPUP_TYPE.OPEN_WINDOW_YN.NO === item.openWindowYn ) {
        // 썸네일 클릭시 이미지를 여는 경우 popupOid를 함께 전달합니다.
        if ( this.$constant.POPUP_TYPE.MOVE.IMAGE === item.moveTypeFlag ) {
          window.location.href = item.linkUrl + "?popupOid=" + item.popupOid;
          return;

        }

        window.location.href = item.linkUrl;
        return;
      }
      else if ( this.$constant.POPUP_TYPE.OPEN_WINDOW_YN.YES === item.openWindowYn ) {  // 새창
        if ( this.$constant.POPUP_TYPE.MOVE.IMAGE === item.moveTypeFlag ) {
          this.$validate.isMobile() ? this.mobileNoGuide( item.linkUrl + "?popupOid=" + item.popupOid ) : window.open(
            item.linkUrl + "?popupOid=" + item.popupOid );
          return;

        }

        this.$validate.isMobile() ? this.mobileNoGuide( item.linkUrl ) : window.open( item.linkUrl );
      }
    },

    mobileNoGuide( url ) {
      if ( url.includes( "GwNoteDataBoxGuide" ) ) {
        return this.$util.rsAlertUtils.rsAlert( { contents : "해당 기능은 모바일에서 지원하지 않으므로 PC 환경에서 확인해주시기를 바랍니다." } );
      }

      window.open( url );
    },

    isContentsType( item ) {
      return this.$validate.isNotEmpty( item ) ?
        this.$validate.isNotEmpty( item.contents )
        && this.$constant.POPUP_TYPE.CONTENTS.EDITOR === item.popupContentsTypeFlag
        : this.$validate.isNotEmpty( item.contents );
    },

    close() {
      this.$emit( "onClose" );
    },

    // 전체보기 클릭 시
    onClickViewAll() {
      this.$emit( "onClickViewAll" );
    },

    // 팝업존 전체보기 닫기 시
    onCloseViewAll() {
      this.$emit( "onCloseViewAll" );

    },
  },
};
</script>
<style></style>
