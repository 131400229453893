<template>
  <div class="header-search" :class="{ 'is-opened': isSearchOpen }">
    <div class="inner">
      <div class="logo-container" @click="$router.push( '/portal/user/main/user_main' )">
        <img class="logo" src="@/assets/images/common/logo/logo.png" alt="강원특별자치도교육청 교육과학정보원">
        <img class="symbol" src="@/assets/images/common/logo/logo_symbol.png" alt="강원특별자치도교육청 교육과학정보원">
      </div>
      <the-main-search ref="headerSearch"/>
      <div class="button-group mob-visible-flex">
        <el-button class="search-btn" @click="toggleSearch">
          <span class="material-icons-outlined">search</span>
        </el-button>
        <el-button class="menu-btn" @click="$emit( 'toggle-mob-menu', true, 'hidden' )">
          <span class="material-icons-round">menu</span>
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import TheMainSearch from "~/components/portal/user/main/TheMainSearch.vue";

export default {
  components: {
    TheMainSearch
  },

  data() {
    return {
      isSearchOpen: false,
    }
  },

  methods: {
    toggleSearch() {
      this.isSearchOpen = !this.isSearchOpen;
    },

    handleClickOutside( event ) {
      const headerSearch = this.$refs.headerSearch?.$el;
      const searchButton = this.$el.querySelector( ".search-btn" );

      if ( this.isSearchOpen && !headerSearch.contains( event.target ) && !searchButton.contains( event.target ) ) {
        this.isSearchOpen = false;
      }
    },
  },

  mounted() {
    document.addEventListener( "click", this.handleClickOutside );
  },

  beforeDestroy() {
    document.addEventListener( "click", this.handleClickOutside );
  }
}
</script>

<style lang="scss">

</style>
