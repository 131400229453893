<template>
  <!-- overlay-container :: 레이어 팝업 -->
  <div :class="[layerPopupVisible ? 'visible' : '']" class="popup-container layer-popup" v-if="layerPopupVisible">
    <div class="inner-wrap">
      <!-- 컨텐츠 -->
      <div class="popup-content" v-for="item in popupList" :key="item.popupOid" :style="setPopupPosition(item)">
        <div class="inner-content" v-if="item.visible">
          <div class="content-body" :style="setPopupSize(item)" style="overflow: hidden">
            <!-- 여백이나 스크롤 이런것 때문에 두 분류로 구분 -->
            <!-- 이미지만 있을 경우 :: img-only 클래스 붙음 -->
            <div class="contents-box img-only"
                 v-if="$validate.isNotEmpty( popupInfo ) ? $validate.isNotEmpty( item.fileList ) && $constant.POPUP_TYPE.CONTENTS.IMAGE === popupInfo.popupContentsTypeFlag : $validate.isNotEmpty( item.fileList )">
              <!-- 에디터에서 입력한 내용은 아래 contents에. -->
              <div class="contents">
                <!-- <img
                  src="@/assets/lms/images/common/sample/sample_01.jpeg"
                  alt="팝업 이미지"
                /> -->
                <a @click="setImgUrl(item)"> <img alt="" :src="getImage(item)"/>
                </a>
              </div>
            </div>
            <!-- 이미지 + 글이나 글만 있을 경우 :: scroll 만드는 엘리먼트 있음. -->
            <!-- 2021.06.22 froala editor 컨텐츠는 출력 안함 -->
            <div class="contents-box"
                 v-if="$validate.isNotEmpty( popupInfo ) ? $validate.isNotEmpty( item.contents ) && $constant.POPUP_TYPE.CONTENTS.EDITOR === popupInfo.popupContentsTypeFlag : $validate.isNotEmpty( item.contents )">
              <!-- scroll area -->
              <div class="scroll-element" v-bar="{ preventParentScroll: true }">
                <!-- el1 -->
                <div>
                  <!-- 에디터에서 입력한 내용은 아래 contents에. -->
                  <div class="contents">
                    <!-- <img
                      src="@/assets/lms/images/common/sample/sample_03.jpg"
                      alt="팝업 이미지"
                    /> -->
                    <!-- 공지내용입니다. -->
                    <!-- 시스템 점검 임시 -->
                    <div class="tc">
                      <!-- <i class="material-icons-round" style="font-size: 10rem;">
                        construction
                      </i> -->
                      <p v-html="item.contents"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-footer">
            <div class="btn-row">
              <button class="btn-close-round" @click="hideToday(item)" v-if="!isPreviewMode && !ignoreCookie">
                <span class="txt">오늘 하루 열지 않기</span> <i class="material-icons">close</i>
              </button>
              <button class="btn-close-round" @click="close(item)">
                <span class="txt">닫기</span> <i class="material-icons">close</i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 모바일 버전일 때만 보이는 footer입니다 -->
    <div class="container-footer mob-visible" v-if="!isPreviewMode && $validate.isMobile() && popupList.length > 0">
      <div class="btn-row">
        <button v-if="!ignoreCookie" class="btn-close-round" round @click="hideAllToday()">
          <span class="txt">전체 팝업 하루동안 열지 않기</span> <i class="material-icons">close</i>
        </button>
        <button class="btn-close-round" round @click="()=> popupList.forEach( popup => close( popup ) )">
          <span class="txt">전체 팝업 닫기</span> <i class="material-icons">close</i>
        </button>
      </div>
    </div>
    <!-- 배경 :: 클릭 시 닫히는 이벤트 동작 -->
    <!--        <div class="close-backdrop" @click="layerPopupVisible=false"></div>-->
  </div>
</template>
<script>
import Cookies from 'js-cookie';

export default {
  props : {
    visible : {
      type : Boolean,
      default : false,
    },
    // 미리보기 시 받아오는 popupInfo
    popupInfo : {
      type : Object,
      default() {
        return {};
      },
    },
    // 미리보기(preview)
    type : {
      type : String,
      default : '',
    },
    popupList : {
      type : Array,
      default() {
        return [];
      },
    },
    // 쿠키를 무시하고 팝업을 띄울 건지 여부
    ignoreCookie : {
      type : Boolean,
      required : false,
      default : false,
    },
    // 부모에서 scroll 의 overflow 를 제어할 건지 여부
    isParentScrollControl : {
      type : Boolean,
      required : false,
      default : false,
    },
  },
  data() {
    return {
      layerPopupVisible : this.visible,
    };
  },
  computed : {

    internalPopupList() {
      return this.popupList;
    },

    // 미리보기 모달일 경우
    isPreviewMode() {
      return "preview" === this.type;
    },

  },
  watch : {
    // 모달 열릴 때 스크롤 막기 by lje
    layerPopupVisible() {
      this.setScrollOverflow();
    },

    visible() {
      this.layerPopupVisible = this.visible;
    },

    popupList() {

      if ( this.$validate.isEmpty( this.internalPopupList ) ) {
        this.initInternalPopupList();
      }
      else {
        this.layerPopupVisible = false;
      }
    },
  },
  fetch() {

    // 관리자 등록, 수정화면에서 미리보기
    if ( this.$validate.isNotEmpty( this.popupInfo ) ) {

      this.popupInfo.visible = true;
      this.internalPopupList.push( this.popupInfo );
    }

    // 팝업리스트 초기설정
    this.initInternalPopupList();
  },

  mounted() {
    this.setScrollOverflow();
  },
  methods : {

    // 팝업 리스트 초기설정을 해줍니다.
    initInternalPopupList() {

      if ( this.$validate.isEmpty( this.internalPopupList ) ) {
        return;
      }

      this.internalPopupList.forEach( popup => {

        popup.visible = true;

        if ( !this.ignoreCookie ) {
          this.checkCookie( popup );
        }

        this.checkOverSize( popup );
      } );
    },

    // 닫기
    close( item ) {

      item.visible = false;

      let visibleList = this.internalPopupList.filter( popup => popup.visible );

      // visibleList가 비어있으면 전체를 닫는거네
      if ( this.$validate.isEmpty( visibleList ) ) {
        this.$emit( 'close' );
        this.layerPopupVisible = false;
      }

      if ( this.isPreviewMode ) {

        this.$emit( 'close' );
        this.$forceUpdate();
      }

      this.$forceUpdate();
    },
    hideAllToday() {

      if ( this.$validate.isEmpty( this.popupList ) ) {
        return;
      }

      this.internalPopupList.forEach( popup => this.hideToday( popup ) );
    },
    // 오늘 하루 열지 않기
    hideToday( item ) {
      Cookies.set( this.$constant.POPUP_HIDE_TODAY + item.popupOid, true, { expires : 1 } );
      this.close( item );
    },

    checkCookie( item ) {
      if ( Cookies.get( this.$constant.POPUP_HIDE_TODAY + item.popupOid ) ) {
        this.close( item );
      }
    },

    getImage( item ) {
      if ( this.$validate.isEmpty( item ) || this.$validate.isEmpty( item.fileList ) ) {

        return;
      }

      // [YP20240403] 2024-04-03 썸네일 파일을 찾아 getIamge로 path 넘길 수 있게 수정
      const thumbnailFile = item.fileList.filter(
        file => this.$constant.FILE_TYPE.IMG !== file.fileType )[0];

      return this.$util.fileUtils.getThumbnailPath( thumbnailFile.storageFileUid,
        item.width + '_' + item.height );
    },

    setPopupPosition( item ) {
      const { positionY, positionX, centerAlignmentYn } = item;
      if ( this.$constant.FLAG_YN.NO === centerAlignmentYn ) {
        return `top: ${ positionY }px; left: ${ positionX }px;`;
      }
      else {
        return `top: 50%; left: 50%; transform : translate(-50% , -50%)`;
      }
    },

    /**
     * 높이를 가로사이즈에 대해 auto 로 지정하여 사진 영역과 닫기 버튼과의 벌어지는 문제를 해결
     * @param item
     * @returns {string}
     */
    setPopupSize( item ) {

      let { width } = item;

      return `width : ${ width }px; height : auto;`;

      // 기존에는 height 를 그대로 렌더링했었음 -> 높이를 큰 값으로 설정할 경우 이슈가 발생함.
      // return `width : ${ width }px; height: ${ height }px;`;
    },

    /**
     *  팝업사이즈가 화면사이즈보다 큰경우를 감지해서 사이즈를 화면보다는 작게 재설정해줍니다.
     */
    checkOverSize( item ) {

      let { width, height, positionY, positionX } = item;

      // 설정할 값
      let calcHeight = 300;
      let calcWidth = 100;

      const currentWidth = width + positionX;
      const currentHeight = height + positionY;

      let screenWidth = window.outerWidth;
      let screenHeight = window.outerHeight;
      let deadlineWidth = screenWidth - (calcWidth / 2);

      if ( this.$validate.isMobile() ) {
        // screenHeight = window.outerHeight;
        calcHeight = 200;
      }

      if ( currentWidth >= deadlineWidth ) { // 1500 >= 1570
        item.width = (screenWidth - calcWidth);
        item.positionX = 50;
      }

      if ( currentHeight >= screenHeight ) {
        item.height = (screenHeight - calcHeight);
        item.positionY = 50;
      }
    },

    /* 링크 타입이 새창인지, 페이지 이동인지 구분하여 이동 */
    setImgUrl( item ) {

      if ( this.$validate.isEmpty( item ) || this.$validate.isEmpty( item.linkUrl ) ) {
        return;
      }

      // [YP20240412] 2024-04-12 linkTypeFlag 값을 가지고 있으면 openWindowYn값으로 변경
      if ( this.$validate.isNotEmpty( item.linkTypeFlag ) ) {
        if ( this.$constant.POPUP_TYPE.LINK.PAGE === item.linkTypeFlag ) {
          item.openWindowYn = this.$constant.POPUP_TYPE.OPEN_WINDOW_YN.NO;
        }
        else {
          item.openWindowYn = this.$constant.POPUP_TYPE.OPEN_WINDOW_YN.YES;
        }
      }

      // 이동할 URL -> 기본으로 linkUrl 세팅
      // 이동 방식 URL 선택 시 사용자가 입력한 링크 URL
      // 새 이미지 선택시 https://gwedu.go.kr/portal/popupImg/popup_img
      let moveUrl = item.linkUrl;

      // 이동 방식 > 새 이미지
      if ( this.$constant.POPUP_TYPE.MOVE.IMAGE === item.moveTypeFlag ) {

        // 미리보기 모드 일 경우 popupOid가 없으면 return
        if ( this.isPreviewMode && this.$validate.isEmpty( item.popupOid ) ) {
          this.$util.rsAlertUtils.rsAlert( { contents : "팝업 저장 후 새 이미지로 이동 가능합니다." } )
          return;
        }

        // 새 이미지 페이지 링크 ( https://gwedu.go.kr/portal/popupImg/popup_img?popupOid=1ULO2AUH06B )
        moveUrl = item.linkUrl + '?popupOid=' + item.popupOid;
      }

      // 페이지 이동
      if ( this.$constant.POPUP_TYPE.OPEN_WINDOW_YN.NO === item.openWindowYn ) {
        window.location.href = moveUrl;
      }
      // 새창
      else if ( this.$constant.POPUP_TYPE.OPEN_WINDOW_YN.YES === item.openWindowYn ) {

        this.$validate.isMobile() ? this.mobileNoGuide( moveUrl ) : window.open( moveUrl );
      }
    },

    /**
     *  모달열릴때 스크롤 설정
     */
    setScrollOverflow() {

      // 부모에서 컨트롤하고 있을 경우 return
      if ( this.isParentScrollControl ) {
        return;
      }

      if ( this.layerPopupVisible ) {
        document.querySelector( '.wrapper' ).style.overflow = "hidden"
      }
      else {
        document.querySelector( '.wrapper' ).style.overflow = this.$validate.isMobile() ? "visible" : "";
      }

    },
  },
};
</script>
<style></style>
